import { ref, computed, watch } from '@vue/composition-api'
import store from '@/store'
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default function useCalendarEventHandler(props, clearForm, emit) {
  // ------------------------------------------------
  // eventLocal
  // ------------------------------------------------
  const eventLocal = ref(JSON.parse(JSON.stringify(props.event.value)))
  const resetEventLocal = () => {
    eventLocal.value = JSON.parse(JSON.stringify(props.event.value))
  }
  watch(props.event, () => {
    resetEventLocal()
  })

  const { updateItem, updateItems, ObjectId } = realmConnection()

  const improvementsToUpdate = ref([])
  const improvementsToDelete = ref([])

  const optionsMetadata = computed(() => store.state["app-todo"].optionsMetadata)
  const changeInstanceMessage = computed(() => i18n.t('message.invalid_instance'))

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ------------------------------------------------
  watch(props.isEventHandlerSidebarActive, val => {
    // ? Don't reset event till transition is finished
    if (!val) {
      setTimeout(() => {
        clearForm.value()
        emit('reset-isInstance')
      }, 350)
    }
  })
  // ------------------------------------------------
  // calendarOptions
  // ------------------------------------------------
  const calendarOptions = computed(() => store.state.calendar.calendarOptions)

  const onSubmit = () => {
    const eventData = JSON.parse(JSON.stringify(eventLocal))
    
    // Check if instance is invalid
    if (eventData.value.extendedProps?.metadata?.length) {
      for (const m of eventData.value.extendedProps.metadata) {
        if (m.name === "instance") {
          if (!optionsMetadata.value[m.name]?.find(e => e === m.answer)) return
          break
        }
      }
    }

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.event.value.id) emit('update-event', eventData.value, improvementsToUpdate.value, improvementsToDelete.value)
    else emit('add-event', eventData.value)

    // Update improvements
    if (improvementsToUpdate.value.length) {
      improvementsToUpdate.value.forEach(e => {
        const query = { _id: ObjectId(e._id) }
        const payload = {
          note: e.note || '',
          assignee: ObjectId(e.assignee._id),
          subscribers: e.subscribers?.map(e => ObjectId(e)) || [],
          tags: e.tags || [],
          dueDate: e.dueDate ? new Date(`${e.dueDate.slice(0, 10)} 12:00:00`) : null,
          metadata: e.metadata || [],
          description: e.description || '',
          completed: e.completed || false,
          deleted: e.deleted || false,
          important: e.important || false,
          evidenceKey: e.evidenceKey || {},
        }
        const action = { $set: payload }
  
        try {
          updateItem({ collection: 'improvement', query, action })
        } catch (error) {
          console.log(error)
        }
      })
    }

    // Delete improvements
    if (improvementsToDelete.value.length) {
      const query = { _id: { $in: improvementsToDelete.value.map(e => ObjectId(e._id)) } }
      const action = { $set: { deleted: true } }
      
      try {
        updateItems({ collection: 'improvement', query, action })
      } catch (error) {
        console.log(error)
      }
    }

    // Close sidebar
    emit('update:is-event-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  /* eslint-disable global-require */
  const guestsOptions = [
    { avatar: require('@/assets/images/avatars/1-small.png'), name: 'Jane Foster' },
    { avatar: require('@/assets/images/avatars/3-small.png'), name: 'Donna Frank' },
    { avatar: require('@/assets/images/avatars/5-small.png'), name: 'Gabrielle Robertson' },
    { avatar: require('@/assets/images/avatars/7-small.png'), name: 'Lori Spears' },
    { avatar: require('@/assets/images/avatars/9-small.png'), name: 'Sandy Vega' },
    { avatar: require('@/assets/images/avatars/11-small.png'), name: 'Cheryl May' },
  ]
  /* eslint-enable global-require */

  return {
    eventLocal,
    resetEventLocal,
    calendarOptions,

    // UI
    guestsOptions,
    onSubmit,
    improvementsToUpdate,
    improvementsToDelete,
    optionsMetadata,
    changeInstanceMessage,
  }
}
