import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import awsConnection from '@/views/habit/aws'
import { useRouter } from '@core/utils/utils'

export default function useTaskHandler(props, emit) {
  // ------------------------------------------------
  // taskLocal
  // ------------------------------------------------
  const taskLocal = ref(JSON.parse(JSON.stringify(props.task.value)))
  const originalCompletedState = ref(false)
  const { router } = useRouter()

  const userData = store.state?.userStore?.userData

  const compressor = ref(null)
  const imgData = ref({})

  const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
  const showModal = ref(false)
  const docSelector = ref(null)
  const okClicked = ref(false)
  const docEvidence = ref(null)
  const imgEvidence = ref(null)
  const disabledImg = ref(false)
  const disabledDoc = ref(false)
  const deleteEvidence = ref(false)
  const { singleUpload } = awsConnection()
  
  const resetTaskLocal = () => {
    taskLocal.value = JSON.parse(JSON.stringify(props.task.value))
    originalCompletedState.value = taskLocal.value.completed
    imgData.value = {}
  }

  watch(props.task, () => {
    resetTaskLocal()
  })

  const onSubmit = async () => {
    const taskData = JSON.parse(JSON.stringify(taskLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event
    if (props.task.value._id || props.taskIndex.value !== -1) {
      const action = taskData.value.completed && !originalCompletedState.value ? "completeFromForm" : ""

      // Save evidence of completed commit in AWS and DB
      const evidenceFile = imgEvidence.value ? imgEvidence.value : docEvidence.value
      const type = imgEvidence.value ? 'img' : 'doc'
      if(evidenceFile) {
        try {
          const key = await saveEvidenceAWS(evidenceFile)
          taskData.value.evidenceKey = { key, type }
        } catch (err) {
          console.error(err)
        }
      }
      // Delete evidence of commitment in DB
      if(deleteEvidence.value) {
        delete taskData.value.evidenceKey
        taskData.value.evidenceKey_unset = true
      }
      emit('update-task', taskData.value, props.taskIndex.value, action)
    } else {
      if (imgData.value.fileInfo) taskData.value.imgData = imgData.value
      emit('add-task', taskData.value)
    }

    // Close sidebar
    emit('update:is-task-handler-sidebar-active', false)
  }

  const uploadImage = () => {
    compressor.value.$el.click()
  }

  const getImgCompressed = (obj) => {
    imgData.value = {
      fileInfo: obj.compressed,
      destinationFolder: `${userData.client.$oid}/improvements`
    }
  }

  const resetVariables = () => {
    // Set toggle task handler sidebar in store to false
    store.commit('app-todo/SET_TOGGLE_TASK_HANDLER_SIDEBAR', false)
    docEvidence.value = null
    imgEvidence.value = null
    disabledImg.value = false
    disabledDoc.value = false
    okClicked.value = false
    deleteEvidence.value = false
  }

  const goToOrigin = (origin, originId) => {
    switch (origin) {
      case 'event':
        router.push({ name: "apps-calendar", query: { originId } })
        break;
      case 'problem_solving':
        router.push({ name: "apps-problem-solving", query: { originId } })
        break;
      case 'confirmation':
        router.push({ path: `/habit/confirmation/view/${originId}` })
        break;
      default:
        break;
    }
  }

  const changeTaskStatus = () => {
    if(commitmentFunctionality) {
      (!taskLocal.value.completed || taskLocal.value.evidenceKey)
        ? showModal.value = true
        : taskLocal.value.completed = !taskLocal.value.completed
    } else {
      taskLocal.value.completed = !taskLocal.value.completed
    }
  }

  const handleModalHide = () => {
    if(!okClicked.value) resetVariables() 
  }

  const handleModalOk = () => {
    if(taskLocal.value.completed) deleteEvidence.value = true
    taskLocal.value.completed = !taskLocal.value.completed
    okClicked.value = true
  }
  
  const uploadImageOrFile = (type) => {
    type === 'img'? uploadImage() : docSelector.value.click()
  }

  const getDocEvidence = (e) => {
    docEvidence.value = e.target.files[0]
    disabledImg.value = true
  }

  const getImgEvidence = (obj) => {
    imgEvidence.value = obj.compressed
    disabledDoc.value = true
  }

  const saveEvidenceAWS = (file) => {
    const destinationFolder = `${userData.client.$oid}/evidenceCommitments`;
    return new Promise((resolve, reject) => {
      singleUpload(file, destinationFolder)
        .then((key) => resolve(key))
        .catch((err) => reject(err))
  })
}

  return {
    taskLocal,
    resetTaskLocal,
    onSubmit,
    uploadImage,
    getImgCompressed,
    compressor,
    imgData,
    resetVariables,
    goToOrigin,
    showModal,
    changeTaskStatus,
    handleModalOk,
    uploadImageOrFile,
    docEvidence,
    getDocEvidence,
    docSelector,
    handleModalHide,
    getImgEvidence,
    imgEvidence,
    disabledImg,
    disabledDoc,
    deleteEvidence
  }
}
