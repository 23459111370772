<template>
  <div class="calendar-wrapper">
    <b-row v-if="isOnline">
      <b-col>
        <b-card no-body :class="[ 'pt-2 px-2', isDemoUser ? 'mt-3' : (isImageAvailable ? 'mt-3 mt-md-0' : 'mt-0')]">
          <filters
            :zoneFilterShow="zoneAgencyFilter"
            :agencyFilterShow="zoneAgencyFilter"
            :locationFilterShow="true"
            :roleFilterShow="true"
            :workerFilterShow="true"
            :monthFilterShow="true"
            :yearFilterShow="true"
            :calendarMonth="calendarMonth"
            :calendarYear="calendarYear"
            @changeFilter="updateFilters"
          />
        </b-card>
      </b-col>
    </b-row>

    <!-- Calendar Stats -->
    <calendar-stats
      :events="eventsForStats"
      :isLoading="isLoading"
    />

    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">
        <!-- Sidebar -->
        <div
          class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
          :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar
            :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
            :isOnline="isOnline"
            @refreshCalendar="refetchEvents"
            @newInstance="openInstanceEventHandler"
          />
        </div>
  
        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">
              <full-calendar
                ref="refCalendar"
                :options="calendarOptions"
                class="full-calendar"
              />
            </div>
          </div>
        </div>
  
        <!-- Sidebar Overlay: Event Handler -->
        <div
          class="body-content-overlay"
          :class="{'show': isCalendarOverlaySidebarActive}"
          @click="isCalendarOverlaySidebarActive = false"
        />
        <calendar-event-handler
          v-model="isEventHandlerSidebarActive"
          :event="event"
          :clear-event-data="clearEventData"
          :isOnline="isOnline"
          :isInstance="isInstance"
          @remove-event="removeEvent"
          @add-event="addEvent"
          @update-event="updateEvent"
          @reset-isInstance="isInstance = false"
        />

      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'
import store from '@/store'
import { ref, onMounted, onUnmounted, watch, computed } from '@vue/composition-api'
import calendarStoreModule from './calendarStoreModule'
import CalendarSidebar from './calendar-sidebar/CalendarSidebar.vue'
import CalendarEventHandler from './calendar-event-handler/CalendarEventHandler.vue'
import useCalendar from './useCalendar'
import CalendarStats from './calendar-stats/CalendarStats.vue'
import Filters from "@/views/organization/Filters.vue";

export default {
  components: {
    FullCalendar, // make the <FullCalendar> tag available
    CalendarSidebar,
    CalendarEventHandler,
    CalendarStats,
    Filters,
  },
  setup() {
    const CALENDAR_APP_STORE_MODULE_NAME = 'calendar'
    const isOnline = computed(() => store.state.app.isOnline)
    const zoneAgencyFilter = localStorage.getItem("clientData")
      ? JSON.parse(localStorage.getItem("clientData")).zone_agency_filter
      : false
    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const { project } = JSON.parse(localStorage.getItem('userData') || '{}')
    const isDemoUser = project?.startsWith("demoUser")
    const clientDataName = JSON.parse(localStorage.getItem("clientData") || '{}').name
    const imageName = clientDataName?.toLowerCase() + '.png'
    const isImageAvailable = computed(() => {
      try {
        require(`@/assets/images/clients_logo/${imageName}`);
        return true
      } catch (e) {
        return false
      }
    })

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)

    // Add event listener for connectivity status detection
    onMounted(() => {
      if (!isOnline.value) store.commit('calendar/SET_SELECTED_EVENTS', ['Pendientes', 'Vencidas', 'En espera'])
      else if(commitmentFunctionality) store.commit('calendar/SET_SELECTED_EVENTS', ['Pendientes', 'Vencidas', 'En espera', 'Futuras', 'Realizadas'])
    })

    // UnRegister and remove event listeners on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    });

    watch(isOnline, () => {
      if (!isOnline.value) store.commit('calendar/SET_SELECTED_EVENTS', ['Pendientes', 'Vencidas', 'En espera'])
      else if(commitmentFunctionality) store.commit('calendar/SET_SELECTED_EVENTS', ['Pendientes', 'Vencidas', 'En espera', 'Futuras', 'Realizadas'])
    })

    const {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      fetchEvents,
      refetchEvents,
      calendarOptions,
      updateFilters,
      eventsForStats,
      calendarMonth,
      calendarYear,
      isLoading,
      isInstance,
      openInstanceEventHandler,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    } = useCalendar()

    return {
      refCalendar,
      isCalendarOverlaySidebarActive,
      event,
      clearEventData,
      addEvent,
      updateEvent,
      removeEvent,
      refetchEvents,
      updateFilters,
      calendarOptions,
      eventsForStats,
      calendarMonth,
      calendarYear,
      isOnline,
      isLoading,
      zoneAgencyFilter,
      isInstance,
      openInstanceEventHandler,
      isDemoUser,
      isImageAvailable,

      // ----- UI ----- //
      isEventHandlerSidebarActive,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/apps/calendar.scss";
</style>
