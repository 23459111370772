<template lang="pug">
b-row
  b-col(cols="12")
    b-card-group.p-0(deck)
      // Total
      b-card(v-show="!isLoading && commitmentFunctionality" bg-variant="transparent", border-variant="secondary")
        b-card-body.p-0.text-center
          b-card-title.text-secondary {{ data.total }}
          b-card-text
            | {{ commitmentFunctionality ? $t('message.tableHeader.total_commitments') : $t('message.tableHeader.total') }}

      // Completed
      b-card(v-show="!isLoading && data.completed > 0" bg-variant="transparent", border-variant="success")
        b-card-body.p-0.text-center
          b-card-title.text-success {{ data.completed }}
          b-card-text
            | {{ commitmentFunctionality ? $t('message.tableHeader.commitments_completed') : $t('message.tableHeader.completed') }}

      // Pending
      b-card(v-show="!isLoading && data.pending > 0" bg-variant="transparent", border-variant="warning")
        b-card-body.p-0.text-center
          b-card-title.text-warning {{ data.pending }}
          b-card-text
            | {{ commitmentFunctionality ? $t('message.tableHeader.commitments_pending') : $t('message.tableHeader.pending') }}

      // Overdue
      b-card(v-show="!isLoading && data.overdue > 0" bg-variant="transparent", border-variant="danger")
        b-card-body.p-0.text-center
          b-card-title.text-danger {{ data.overdue }}
          b-card-text
            | {{ commitmentFunctionality ? $t('message.tableHeader.commitments_overdue') : $t('message.tableHeader.overdue') }}
     
      // Future
      b-card(v-show="!isLoading && data.future > 0" bg-variant="transparent", border-variant="info")
        b-card-body.p-0.text-center
          b-card-title.text-info {{ data.future }}
          b-card-text
            | {{ $t('message.tableHeader.future') }}

      // On Hold
      b-card(v-show="!isLoading && data.onHold > 0" bg-variant="transparent", border-variant="warning")
        b-card-body.p-0.text-center
          b-card-title.text-warning {{ data.onHold }}
          b-card-text
            | {{ $t('message.tableHeader.onHold') }}

      // Loader
      b-card.py-1(v-show="isLoading" bg-variant="transparent", border-variant="secondary")
        b-card-body.text-center
          loading(
            :active="true" 
            :is-full-page="false"
            :color="colors.primary"
            :opacity="0"
          )
</template>

<script>
import { ref, onMounted, watch } from "@vue/composition-api"
import { BCardBody, BCardTitle, BCardText, BCardGroup } from "bootstrap-vue"
// import useCalendarStatus from "./useCalendarStats";
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { colors } from '@/constants'


export default {
  props: {
    events: {
      type: Array,
      default: () => ([]),
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    Loading,
  },

  setup(props) {
    const commitmentFunctionality = JSON.parse(localStorage.getItem("clientData") || '{}').commitment_functionality
    const now = new Date()
    now.setHours(12, 0, 0, 0)
    //const { data, fetchEvents } = useCalendarStatus()
    const data = ref({
      overdue: 0,
      pending: 0,
      completed: 0,
      future: 0,
      onHold: 0,
      total: 0,
    })

    function calcStats() {
      commitmentFunctionality ? calcInstancesStats() : calcEventsStats()
    }

    function calcEventsStats() {
      const overdue = props.events.filter(
        (event) => event.extendedProps.calendar === "Vencidas" && !event.confirmation
      ).length
      const pending = props.events.filter(
        (event) => event.extendedProps.calendar === "Pendientes" && !event.confirmation
      ).length
      const completed = props.events.filter(
        (event) => event.extendedProps.calendar === "Realizadas"
      ).length
      const future = props.events.filter(
        (event) => event.extendedProps.calendar === "Futuras" && !event.confirmation
      ).length
      const onHold = props.events.filter(
        (event) => event.extendedProps.calendar !== "Realizadas" && event.confirmation
      ).length

      data.value = {
        overdue,
        pending,
        completed,
        future,
        onHold,
        total: overdue + pending + completed + future + onHold,
      }
    }

    function calcInstancesStats() {
      let overdue = 0
      let pending = 0
      let completed = 0
      let future = 0
      let onHold = 0

      props.events.forEach(e => {
        if (e.improvements?.length) {
          e.improvements.forEach(i => {
            const dueDateFormatted = i.dueDate ? new Date(`${i.dueDate.slice(0, 10)} 12:00:00`) : null
            if (i.completed) completed += 1
            else {
              if (dueDateFormatted < now) overdue += 1
              else pending += 1
            }
          })
        }
        if (e.isBehaviourEvaluation) {
          if (e.extendedProps.calendar === "Vencidas" && !e.confirmation) overdue += 1
          else if (e.extendedProps.calendar === "Pendientes" && !e.confirmation) pending += 1
          else if (e.extendedProps.calendar === "Realizadas") completed += 1
          else if (e.extendedProps.calendar === "Futuras" && !e.confirmation) future += 1
          else if (e.extendedProps.calendar !== "Realizadas" && e.confirmation) onHold += 1
        }
      })

      data.value = {
        overdue,
        pending,
        completed,
        future,
        onHold,
        total: overdue + pending + completed + future + onHold
      }
    }

    onMounted(calcStats)
    watch(props, () => calcStats())

    return {
      data,
      commitmentFunctionality,
      colors
    }
  }
}
</script>
