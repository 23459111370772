<template lang="pug">
#calendar-sync-wrapper
  b-button.mb-1(
    block,
    variant="success",
    :disabled="isSubmitting || !isOnline",
    v-b-modal="'modal-calendar-sync'"
    style="height: 2.8rem"
  )
    | {{ $t('message.sync_with_outlook') }}
  b-modal(
    id="modal-calendar-sync"
    centered
    no-close-on-backdrop
    modal-class="modal-info"
    ok-variant="info"
    cancel-variant="outline-secondary"
    :title="$t('message.confirm_action')"
    :ok-title="$t('message.sync')"
    :cancel-title="$t('message.cancel')"
    @ok="sync"
  )
    | {{ $t('message.confirm_sync_outlook') }}
  p.small {{ message }}
</template>

<script>
import store from "@/store";
import { BButton, BModal, VBModal } from "bootstrap-vue";
import { ref } from '@vue/composition-api'
import { timeout } from "q";
import i18n from '@/libs/i18n'
import realmConnection from '@/views/habit/realm'

export default {
  components: {
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    isOnline: {
      type: Boolean,
      require: true,
    },
  },
  setup(props, { emit }) {
    const { getItemsWithAggregate, createItem, updateItem, getItems, ObjectId } = realmConnection()
    const getUserData = store.state?.userStore?.userData;
    const userId = getUserData.worker_id != null ? getUserData.worker_id.$oid : null;
    const clientId = getUserData.client.$oid
    const isSubmitting = ref(false)

    // Interface messages
    const message = ref("");

    async function fetchEvents() {
      try {
        const initialQuery = {
          client_id: ObjectId(clientId),
          deleted: { $ne: true },
          'extendedProps.calendar': { $in: ["Pendientes", "Vencidas", "En espera"] },
        }
  
        const finalQuery = {
          $or: [{ "attendee.deleted": { $ne: true } }, { attendee: { $exists: false } }]
        }
  
        if (getUserData.role === "supervisor") {
          finalQuery.$or[0]["attendee.supervisors"] = ObjectId(userId)
        }
        
        const pipeline = [
          { $match: initialQuery },
          { $lookup: { from: 'worker', localField: 'attendee', foreignField: '_id', as: 'attendee', pipeline: [ { $project: { deleted: 1, supervisors: 1, name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
          { $addFields: { attendee: { $arrayElemAt: ["$attendee", 0] } } },
          { $match: finalQuery },
          { $sort: { start: -1 } },
          { $limit : 50 },
          { $lookup: { from: 'process', localField: 'process', foreignField: '_id', as: 'process', pipeline: [ { $project: { name: 1 } }, { $addFields: { _id: { $toString: "$_id" } } } ] } },
          { $addFields: { _id: { $toString: "$_id" }, process: { $arrayElemAt: ["$process", 0] }, start: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$start" } }, end: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$end" } }, modifiedAt: { $dateToString: { format: "%Y-%m-%dT%H:%M:%SZ", date: "$modifiedAt" } } } },
        ]

        const items = await getItemsWithAggregate({ collection: 'event', pipeline })

        return items
      } catch (error) {
        console.log(error)
      }
    }

    async function updateLocalEventWithMSId(event, newEventCreated) {
      try {
        const payload = {
          worker_id: ObjectId(userId),
          event_id: event._id,
          ms_event_id: newEventCreated.id,
          modifiedAt: new Date(),
        }
        await createItem({ collection: 'event_sync', payload })
      } catch (error) {
        console.log(error)
      }
    }

    async function sync(contextThis) {
      isSubmitting.value = true
      message.value = i18n.t('message.starting_sync');
      const msalInstance = this ? this.$msal : contextThis.$msal

      if (msalInstance.isAuthenticated()) {
        const events = await fetchEvents();
        
        if (events.length > 0) {
          await msalInstance.acquireToken()   // This is to ensure that the MSAL API is called with the access token
          
          for (const event of events) {
            const syncedQuery = {
              event_id: event._id,
              worker_id: ObjectId(userId)
            }

            const synced = await getItems({ collection: 'event_sync', query: syncedQuery })
            
            if (synced?.length > 0) {
              const syncLocalEvent = synced[0]
              
              try {
                const syncedMSEvent = await msalInstance.msGraph(
                  `/me/events/${syncLocalEvent.ms_event_id}`
                );
                const localStart = event.start?.substr(0, 16);
                const localEnd = event.end?.substr(0, 16);
                const localModifiedAt = event.modifiedAt || "0";
                const msStart = syncedMSEvent.body?.start?.dateTime?.substr(0, 16);
                const msEnd = syncedMSEvent.body?.end?.dateTime?.substr(0, 16);
                const msModifiedAt = syncedMSEvent.body?.lastModifiedDateTime?.substr(0, 16) || "0";
                
                if (localStart != msStart || localEnd != msEnd) {
                  if (msModifiedAt > localModifiedAt) {
                    message.value = `${i18n.t('message.Updating')} ${event.title ? event.title : i18n.t('message.event')} ${i18n.t('message.in')} Habit.`;

                    let msStartUpdate = new Date(msStart);
                    let msEndUpdate = new Date(msEnd);
                    msStartUpdate.setHours(msStartUpdate.getHours() - 3);
                    msEndUpdate.setHours(msEndUpdate.getHours() - 3);
                    
                    const eventQuery = { _id: ObjectId(event._id) }

                    const payload = {
                      start: msStartUpdate,
                      end: msEndUpdate,
                      modifiedAt: new Date()
                    }

                    const action = { $set: payload }

                    await updateItem({ collection: 'event', query: eventQuery, action })
                  } else if (localStart && localEnd) {
                    message.value = `${i18n.t('message.Updating')} ${event.title ? event.title : i18n.t('message.event')} ${i18n.t('message.in')} Outlook.`;

                    await msalInstance.msGraph({
                      url: `/me/events/${syncLocalEvent.ms_event_id}`,
                      method: "PATCH",
                      data: {
                        start: {
                          dateTime: localStart,
                          timeZone: "UTC",
                        },
                        end: {
                          dateTime: localEnd,
                          timeZone: "UTC",
                        },
                      },
                    });
                  }
                }
              } catch (err) {
                console.log(err);
              }
              continue;
            }

            if (event.start && event.end) {
              // Create the event JSON
              const msEvent = {
                subject: event.attendee ? `${event.attendee.name} - ${event.process?.name}` : `${event.process?.name}`,
                body: {
                  contentType: "HTML",
                  content: `<a href="https://habit.addval.io/habit/confirmation/new/${event.process?._id}/${event.attendee && event.attendee._id || 'worker'}/${event._id}"> Crear confirmación en Habit </a>`,
                },
                start: {
                  dateTime: event.start,
                  timeZone: "Eastern Standard Time",
                },
                end: {
                  dateTime: event.end,
                  timeZone: "Eastern Standard Time",
                },
              };
  
              message.value = event.attendee
                ? `${i18n.t('message.Creating')} ${event.attendee.name} - ${event.process?.name} ${i18n.t('message.date')} ${event.start.toString()} ${i18n.t('message.in')} Outlook.`
                : `${i18n.t('message.Creating')} ${event.process?.name} ${i18n.t('message.date')} ${event.start.toString()} ${i18n.t('message.in')} Outlook.`
  
              // Add Event to MS Calendar
              const newMSEvent = await msalInstance.msGraph({
                url: "/me/events",
                method: "POST",
                data: msEvent,
              });
  
              // Update local event with MS ID
              await updateLocalEventWithMSId(event, newMSEvent.body);
            }
          }

          emit('refreshCalendar')
          message.value = i18n.t('message.calendar_synced');
          isSubmitting.value = false

        }
      } else {
        message.value = i18n.t('message.login_with_microsoft');
        msal.loginPopup(
          {
            scopes: ["user.read", "Calendars.ReadWrite"],
          }
        )
          .then(response => {
            message.value = i18n.t('message.logged_in');
            sync(this);
          })
          .catch(error => {
            message.value = i18n.t('message.microsoft_session_required');
            isSubmitting.value = false
            console.log(error);
          });
      }
    }

    return {
      sync,
      message,
      isSubmitting,
    };
  },
};
</script>